import React, { useState } from 'react';
import FormFields from './formComponents/FormFields';
import ProductList from './formComponents/ProductList';
import EmailReport from './formComponents/EmailReport';
import './App.css';

function App() {
  const [formData, setFormData] = useState({
    mailTo: '',
    mailCC: '',
    mailFrom: '',
    domainName: '',
    mailSubject: '',
    products: [{ name: '', link: '', imgUrl: '', desc: '' }],
  });
  const [submitStatus, setSubmitStatus] = useState(null); // Success or failure status
  const [showPopup, setShowPopup] = useState(false); // Show/hide popup

  // Handle submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    // Sending data to the backend API
    fetch('http://13.233.104.175:8080/api/mail/send', { // Correct API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then((response) => {
      if (response.ok) {
        console.log("mail chali gayi !!")
        setSubmitStatus('success');
      } else {
        throw new Error('Failed to send email');
      }
      return response.text();
    })
    .then((data) => {
      console.log('Success:', data);
      setSubmitStatus('success'); // Set success status
      setShowPopup(true); // Show success popup
    })
    .catch((error) => {
      console.error('Error:', error);
      setSubmitStatus('failure');
      setShowPopup(true); // Show failure popup
    });
};
 // Handle closing the popup
 const closePopup = () => {
  setShowPopup(false);
  // setSubmitStatus(null);
};


  return (
    <div className="App">
       <header className="App-header">
        <h1>Welcome to your personalized email Advertiser</h1>
      </header>
      <form onSubmit={handleSubmit}>
        {/* Main form fields */}
        <FormFields formData={formData} setFormData={setFormData} />

        {/* Product list section */}
        <ProductList products={formData.products} setProducts={(products) => setFormData({ ...formData, products })} />

        {/* Submit button */}
        <button type="submit">Submit</button>
      </form>
        {/* Popup for Success or Failure */}
        {showPopup && (
        <div className={`popup ${submitStatus}`}>
          <div className="popup-content">
            {submitStatus === 'success' ? (
              <p>Email sent successfully!</p>
            ) : (
              <p>Failed to send email. Please try again.</p>
            )}
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
      {submitStatus === 'success' && < EmailReport />}
    </div>
  );
}

export default App;
