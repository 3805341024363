import React, { useState } from 'react';

const EmailReport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchReport = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('http://13.233.104.175:8080/api/mail/generateReport');
      if (!response.ok) {
        throw new Error('Failed to fetch the report');
      }
      const report = await response.text();
      downloadReport(report);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = (report) => {
    const blob = new Blob([report], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'email_report.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container"> {/* Added container class for styling */}
      <h2>Email Report</h2>
      <button onClick={fetchReport} disabled={loading}>
        {loading ? 'Generating Report...' : 'Generate Report'}
      </button>
      {error && <p>{error}</p>} {/* Added error styling */}
    </div>
  );
};


export default EmailReport;
