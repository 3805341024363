import React, { useState } from 'react';

function FormFields({ formData, setFormData }) {
  // Handle form field changes

  const [selectedFile, setSelectedFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
   // Handle CSV file selection
   const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log('Selected file:', file);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    fetch('http://13.233.104.175:8080/api/mail/uploadFile', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log('File uploaded successfully:', data);
        alert('File uploaded successfully!');
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        alert('Failed to upload file.');
      });
  };


  return (
    <div className="form-fields">
      <label>
        Mail To (Optional):
        <input
          type="email"
          name="mailTo"
          value={formData.mailTo}
          onChange={handleInputChange}
        />
      </label>
      <label>OR Import CSV:</label>
      <br />
      <div className="csv-upload-container">
  <label>
    <input type="file" accept=".csv" onChange={handleFileChange} />
  </label>
  <button onClick={handleFileUpload}>Upload CSV</button>
</div>
      <br />
      <label>
        Mail CC (Optional):
        <input
          type="email"
          name="mailCC"
          value={formData.mailCC}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Mail From:
        <input
          type="text"
          name="mailFrom"
          value={formData.mailFrom}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Domain Name:
        <input
          type="text"
          name="domainName"
          value={formData.domainName}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Mail Subject:
        <input
          type="text"
          name="mailSubject"
          value={formData.mailSubject}
          onChange={handleInputChange}
        />
      </label>
    </div>
  );
}

export default FormFields;
