import React from 'react';

function ProductForm({ product, index, handleProductChange, removeProduct }) {
  return (
    <div className="form-fields">
      <h3 className="product-heading">Product {index + 1}</h3>
      <label >
        Name:
        <input
          type="text"
          name="name"
          value={product.name}
          onChange={(e) => handleProductChange(index, e)}
        />
      </label>
      <br />
      <label>
        Link:
        <input
          type="text"
          name="link"
          value={product.link}
          onChange={(e) => handleProductChange(index, e)}
        />
      </label>
      <br />
      <label>
        Image URL:
        <input
          type="text"
          name="imgUrl"
          value={product.imgUrl}
          onChange={(e) => handleProductChange(index, e)}
        />
      </label>
      <br />
      <label>
        Description:
        <textarea
          name="desc"
          value={product.desc}
          onChange={(e) => handleProductChange(index, e)}
        />
      </label>
      <br />
      {/* Remove product button */}
      {index > 0 && (
        <button type="button" onClick={() => removeProduct(index)}>
          Remove Product
        </button>
      )}
      <hr />
    </div>
  );
}

export default ProductForm;
