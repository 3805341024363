import React from 'react';
import ProductForm from './ProductForm';

function ProductList({ products, setProducts }) {
  // Handle product field change
  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = [...products];
    updatedProducts[index][name] = value;
    setProducts(updatedProducts);
  };

  // Add a new product
  const addProduct = () => {
    setProducts([...products, { name: '', link: '', imgUrl: '', desc: '' }]);
  };

  // Remove a product
  const removeProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  return (
    <div>
      <h2>Products</h2>
      {products.map((product, index) => (
        <ProductForm
          key={index}
          product={product}
          index={index}
          handleProductChange={handleProductChange}
          removeProduct={removeProduct}
        />
      ))}
      <button type="button" onClick={addProduct}>
        + Add Another Product
      </button>
    </div>
  );
}

export default ProductList;
